import { canHover } from 'utils';

const useShowGallery = () => {
  const showGallery = ref(false);

  onMounted(() => {
    showGallery.value = canHover();
  });

  return { showGallery: readonly(showGallery) };
};

export { useShowGallery };
