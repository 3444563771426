const MEDIA_QUERY_CAN_HOVER = '(hover: hover) and (pointer: fine)';

/**
 * @return {boolean}
 */
const canHover = () => {
  if (typeof window !== 'undefined') {
    return window.matchMedia(MEDIA_QUERY_CAN_HOVER).matches;
  }

  return false;
};

export { canHover };
